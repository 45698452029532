import { HttpCancelError } from '@cian/peperrors/shared';
import { TDevice } from '@cian/utils';
import { pathOr, equals } from 'ramda';

import { isNewPromoPinsAvailable } from 'shared/map-search/containers/Map/MapLayers/NewbuildingPromoPins/utils/isNewPromoPinsAvailable';
import { selectNewbuildingNewPromoPinsEnabled } from 'shared/map-search/selectors/newbuildingPromoFeatures';

import { assertJsonQueryValidity } from './helpers';
import { EResultsActionType, IFetchClustersSucceedParameters } from './types';
import { prepareClusters } from '../../mappers/clusters/prepareClusters';
import { fetchClusters as requestFetchClusters } from '../../services/clusters';
import { ITilesMap } from '../../types/map';
import { EErrorNotificationType } from '../../types/notifications';
import { TThunkAction } from '../../types/redux';
import { combineBboxes } from '../../utils/geometry';
import { mapBBoxBoundsToBBox } from '../../utils/mapBounds';
import { zoomToPrecision, precisionToMinZoom } from '../../utils/precision';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { getRequiredTiles, getVisibleTiles, tileToBbox } from '../../utils/tiles';
import { pushErrorNotification } from '../notifications';

const setStatusLoading = actionGenerator<EResultsActionType.Loading>(EResultsActionType.Loading);
const setStatusSucceed = actionGenerator<EResultsActionType.Succeed, IFetchClustersSucceedParameters>(
  EResultsActionType.Succeed,
);
const setStatusFailed = actionGenerator<EResultsActionType.Failed>(EResultsActionType.Failed);
let prevBounds: [YMaps.TCoord, YMaps.TCoord] | undefined;

interface IFetchClustersParameters {
  updateBounds?: boolean;
  deviceType?: TDevice;
}

export function fetchClusters({ updateBounds = false }: IFetchClustersParameters = {}): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      config,
      custom: { subdomain },
      logger,
    } = context;
    const state = getState();

    const {
      features,
      filters: { jsonQuery },
      mapBounds,
      detailsVisiblePrecision,
      results: { jsonQuery: resultsJsonQuery },
    } = state;

    const isNewPromoPinsEnabled = selectNewbuildingNewPromoPinsEnabled(state);

    const { bounds, zoom: currentZoom } = mapBounds;
    const isBoundsEquals = equals(bounds, prevBounds);
    prevBounds = bounds;

    if (!bounds || !currentZoom) {
      logger.error('Either bounds or zoom is undefined', { domain: 'actions/results/fetchClusters' });

      return;
    }

    const clean = !equals(jsonQuery, resultsJsonQuery);

    const precision = zoomToPrecision(config, currentZoom);
    const zoom = precisionToMinZoom(config, precision);
    const visibleTiles = getVisibleTiles(bounds, zoom);

    let requiredTiles = visibleTiles;

    const isNewbuildingNewPromoPinsEnabled = isNewPromoPinsAvailable({
      precision,
      detailsVisiblePrecision,
      currentZoom,
      jsonQuery,
      isNewPromoPinsEnabled,
    });

    if (isNewbuildingNewPromoPinsEnabled && !clean && isBoundsEquals) {
      logger.debug('Unfortunately we tried to fetch already loaded tiles', {
        domain: 'actions/results/fetchClusters',
      });

      return;
    }

    if (!isNewbuildingNewPromoPinsEnabled && !clean) {
      const loadedTiles = pathOr<ITilesMap>({}, [precision, 'tiles'], features);

      requiredTiles = getRequiredTiles(loadedTiles, visibleTiles);
      if (requiredTiles.length === 0) {
        logger.debug('Unfortunately we tried to fetch already loaded tiles', {
          domain: 'actions/results/fetchClusters',
        });

        return;
      }
    }

    dispatch(setStatusLoading());

    try {
      const requiredBboxes = requiredTiles.map(t => tileToBbox(t, zoom));
      const combinedBbox = combineBboxes(requiredBboxes);
      const clustersData = await requestFetchClusters(context, {
        subdomain,
        params: {
          zoom: updateBounds ? undefined : zoom,
          bbox: updateBounds ? undefined : [mapBBoxBoundsToBBox({ bounds: combinedBbox })],
          jsonQuery,
          extended: !updateBounds,
        },
      });

      const clusters = prepareClusters({
        data: clustersData,
        mapBounds,
        currentZoom,
      });

      assertJsonQueryValidity(logger, jsonQuery, clusters.jsonQuery);

      dispatch(
        setStatusSucceed({
          ...clusters,
          jsonQuery,
          clean,
          tiles: requiredTiles,
          updateBounds,
        }),
      );
    } catch (error) {
      if (error instanceof HttpCancelError) {
        return;
      }

      logger.error(error);

      dispatch(setStatusFailed());
      dispatch(pushErrorNotification(EErrorNotificationType.FetchClusters));
    }
  };
}
