/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TQueryToGeoTagsModel,
  IQueryToGeoTagsRequest,
  IMappers,
  IQueryToGeoTagsResponse,
  IQueryToGeoTagsResponseError,
  TQueryToGeoTagsResponse,
} from './types';

const defaultConfig: TQueryToGeoTagsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/query-to-geo-tags/',
  hostType: 'api',
} as TQueryToGeoTagsModel;

function createQueryToGeoTagsModel(parameters: IQueryToGeoTagsRequest): TQueryToGeoTagsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IQueryToGeoTagsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IQueryToGeoTagsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchQueryToGeoTags<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IQueryToGeoTagsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TQueryToGeoTagsResponse> {
  const { response, statusCode } = await httpApi.fetch(createQueryToGeoTagsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IQueryToGeoTagsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IQueryToGeoTagsResponseError);
    }
  }

  return { response, statusCode } as TQueryToGeoTagsResponse;
}

export { defaultConfig, createQueryToGeoTagsModel, fetchQueryToGeoTags };
