import {
  EEntityType,
  EDealType,
} from '../../../common/repositories/favorites/entities/add_favorite/AddFavoriteEntityPublicRequestSchema';
import {
  fetchAddFavorite,
  TAddFavoriteResponse_1,
  TAddFavoriteResponse,
} from '../../../common/repositories/favorites/v1/add-favorite';
import { IApplicationContext } from '../../types/applicationContext';
import { ResponseError } from '../../utils/errors/ResponseError';

export async function addNewbuildingToFavorites(
  context: IApplicationContext,
  newbuildingId: number,
): Promise<TAddFavoriteResponse_1> {
  const { httpApi, logger } = context;

  try {
    const response: TAddFavoriteResponse = await fetchAddFavorite({
      httpApi,
      parameters: {
        entityId: newbuildingId,
        dealType: EDealType.Sale,
        entityType: EEntityType.Newbuilding,
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'favorites#addNewbuildingToFavorites',
        message: response.response.message || '',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: response.response.errors as any,
        },
      });
    }

    return response.response;
  } catch (error) {
    logger.error(error);

    throw error;
  }
}
