import * as React from 'react';

import { IHotspotProperties, TFeature } from 'shared/map-search/types/map';

import { useFeaturesContext } from '../context';
import { CLOSE_POPUP_DELAY, OPEN_POPUP_DELAY } from './constants';
import { usePopupState } from './usePopupState';
import { getPopupIndent } from './utils/getPopupIndent';

interface IPopupManagerProps {
  resultsAvailable: boolean;
  detailsVisible: boolean;
}

export const PopupManager: React.FC<IPopupManagerProps> = ({
  resultsAvailable: isResultsAvailable,
  detailsVisible: isDetailsVisible,
}) => {
  const { hotspotsLayer, balloonManager, popupManager } = useFeaturesContext();
  const [popupState, setCurrentFeature] = usePopupState();
  const openTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    popupManager.setState(popupState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState]);

  const handleFeatureHover = React.useCallback(
    (feature: TFeature) => {
      if (feature.properties.newbuilding?.isAnyFicheringPlus) {
        return;
      }

      const [x, y] = feature.geometry.coordinates;
      const indentType = getPopupIndent(feature, { isDetailsVisible, isResultsAvailable });

      setCurrentFeature(feature);

      popupManager.setIndent(indentType);
      popupManager.open([x, y]);
    },
    [popupManager, setCurrentFeature, isResultsAvailable, isDetailsVisible],
  );

  const closePopup = React.useCallback(() => {
    if (openTimeoutHandler.current) {
      clearTimeout(openTimeoutHandler.current);
    }
    if (closeTimeoutHandler.current) {
      clearTimeout(closeTimeoutHandler.current);
    }

    closeTimeoutHandler.current = setTimeout(() => {
      closeTimeoutHandler.current = null;

      popupManager.close();
    }, CLOSE_POPUP_DELAY);
  }, [popupManager]);

  React.useEffect(() => {
    const handleHotspotClick = () => {
      popupManager.close();
    };

    hotspotsLayer.layer.events.add('click', handleHotspotClick);

    return () => {
      hotspotsLayer.layer.events.remove('click', handleHotspotClick);
    };
  }, [popupManager, hotspotsLayer, isResultsAvailable]);

  React.useEffect(() => {
    const handleHotspotMouseEnter = (event: YMaps.IEvent) => {
      if (openTimeoutHandler.current) {
        clearTimeout(openTimeoutHandler.current);
      }
      if (closeTimeoutHandler.current) {
        clearTimeout(closeTimeoutHandler.current);
      }

      openTimeoutHandler.current = setTimeout(() => {
        openTimeoutHandler.current = null;

        const activeObject = event.get<YMaps.IHotspotLayerObject<IHotspotProperties>>('activeObject');
        const activeObjectProperties = activeObject.getProperties();

        handleFeatureHover(activeObjectProperties.originalFeature);
      }, OPEN_POPUP_DELAY);
    };

    hotspotsLayer.layer.events.add('mouseenter', handleHotspotMouseEnter);

    return () => {
      hotspotsLayer.layer.events.remove('mouseenter', handleHotspotMouseEnter);
    };
  }, [hotspotsLayer, handleFeatureHover]);

  React.useEffect(() => {
    const handleBalloonMouseEnter = (event: YMaps.IEvent) => {
      if (openTimeoutHandler.current) {
        clearTimeout(openTimeoutHandler.current);
      }
      if (closeTimeoutHandler.current) {
        clearTimeout(closeTimeoutHandler.current);
      }

      openTimeoutHandler.current = setTimeout(() => {
        openTimeoutHandler.current = null;

        const objectId = event.get<string>('objectId');
        const feature = balloonManager.objectManager.objects.getById<TFeature>(objectId);

        handleFeatureHover(feature);
      }, OPEN_POPUP_DELAY);
    };

    balloonManager.objectManager.objects.events.add('mouseenter', handleBalloonMouseEnter);

    return () => {
      balloonManager.objectManager.objects.events.remove('mouseenter', handleBalloonMouseEnter);
    };
  });

  React.useEffect(() => {
    hotspotsLayer.layer.events.add('mouseleave', closePopup);

    return () => {
      hotspotsLayer.layer.events.remove('mouseleave', closePopup);
    };
  }, [hotspotsLayer, closePopup]);

  React.useEffect(() => {
    balloonManager.objectManager.objects.events.add('mouseleave', closePopup);

    return () => {
      balloonManager.objectManager.objects.events.remove('mouseleave', closePopup);
    };
  }, [balloonManager, closePopup]);

  return null;
};
