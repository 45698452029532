// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetClustersForMapRequest,
  TGetClustersForMapModel,
  IMappers,
  TGetClustersForMapResponse,
  TGetClustersForMapResponse_1,
  TGetClustersForMapResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetClustersForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers-index',
  pathApi: '/v1/get-clusters-for-map/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TGetClustersForMapModel;

export function createGetClustersForMapModel(parameters: TGetClustersForMapRequest): TGetClustersForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetClustersForMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetClustersForMapRequest;
}

export async function fetchGetClustersForMap<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetClustersForMapOptions<TResponse200, TResponse400>): Promise<
  TGetClustersForMapResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetClustersForMapModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetClustersForMapResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetClustersForMapResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetClustersForMapResponse;
}
