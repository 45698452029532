import { TDeviceType } from '@cian/ui-kit';

import { isShowFskBannerOnMapZoom } from './isShowFskBannerOnMapZoom';
import { FOfferType, getTermsValue, offerTypeFromJsonQuery } from '../../../common/packages/JsonQuery';
import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';
import { isFskRegions } from '../../containers/FskBannerContainer/helpers';

interface IIsFSKBannerAvailableArgs {
  zoom: number;
  jsonQuery: IJsonQuery;
  deviceType: TDeviceType;
  subdomain: string;
  isFskBannerEnabled: boolean;
  isShowWithPromoBannerOnMobile?: boolean;
}

export function isFSKBannerAvailable({
  zoom,
  jsonQuery,
  deviceType,
  subdomain,
  isFskBannerEnabled,
  isShowWithPromoBannerOnMobile,
}: IIsFSKBannerAvailableArgs) {
  const isFskRegion = isFskRegions(getTermsValue('region')(jsonQuery), subdomain);
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const isDesktop = deviceType === 'desktop';

  if (!isFskBannerEnabled || !isFskRegion) {
    return false;
  }

  if (isShowWithPromoBannerOnMobile || isShowFskBannerOnMapZoom(zoom, isDesktop)) {
    // смешанная выдача, первичка
    return (offerType & FOfferType.FlatNew) !== 0;
  }

  return false;
}
