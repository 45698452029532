import { IJsonQuery, TGeoValue } from '../../../common/packages/api-models/common/json_query';
import { IQueryToGeoTagsResponseSchema } from '../../../common/repositories/monolith-python/entities/schemas/QueryToGeoTagsResponseSchema';
import { IAddressTag, TResponseGeoTags, TTag } from '../../types/tags';

const geoTypesMap: { [key: string]: TGeoValue['type'] } = {
  Location: 'location',
  Street: 'street',
  Road: 'highway',
  House: 'house',
};

export function prepareGeoTags(response: IQueryToGeoTagsResponseSchema, jsonQuery: IJsonQuery): TTag[] | null {
  const tags: TTag[] = [];

  const { region } = response;

  const responseGeoTags = response.geoTags as TResponseGeoTags;

  if (
    region &&
    (!responseGeoTags.address_tags ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !responseGeoTags.address_tags.some((tag: any) => tag.addressNodes[tag.addressNodes.length - 1].id === region.id))
  ) {
    switch (region.id) {
      case -1: {
        tags.push({ type: 'region', id: 1, name: 'Москва' }, { type: 'region', id: 4593, name: 'Московская область' });
        break;
      }

      case -2: {
        tags.push(
          { type: 'region', id: 2, name: 'Санкт-Петербург' },
          { type: 'region', id: 4588, name: 'Ленинградская область' },
        );
        break;
      }

      default: {
        if (jsonQuery.geo?.value.some(geo => geo.type === 'location' && geo.id === region.id)) {
          tags.push({ type: 'address', geoType: 'location', id: region.id, name: region.fullname });
        } else {
          tags.push({ type: 'region', id: region.id, name: region.fullname });
        }
      }
    }
  }

  if (responseGeoTags.address_tags && responseGeoTags.address_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.address_tags.map((tag: any) => {
        const addressNode = tag.addressNodes[tag.addressNodes.length - 1];

        return {
          type: 'address' as const,
          geoType: geoTypesMap[addressNode.geoType] as IAddressTag['geoType'],
          id: addressNode.id,
          name: tag.text,
        };
      }),
    );
  }

  if (responseGeoTags.developer_tags && responseGeoTags.developer_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.developer_tags.map((tag: any) => ({
        type: 'developer' as const,
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.metro_tags && responseGeoTags.metro_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.metro_tags.map((tag: any) => ({
        type: 'underground' as const,
        id: tag.id,
        color: tag.color,
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.polygon_tags && responseGeoTags.polygon_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.polygon_tags.map((tag: any) => ({
        type: 'polygon' as const,
        coordinates: tag.geoObject.source.geometry.coordinates[0].map((coords: YMaps.TCoord) =>
          coords.map(coord => parseFloat(coord.toFixed(7)).toString()),
        ),
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.railway_tags && responseGeoTags.railway_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.railway_tags.map((tag: any) => ({
        type: 'railway' as const,
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.district_tags && responseGeoTags.district_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.district_tags.map((tag: any) => ({
        type: 'district' as const,
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.newobject_tags && responseGeoTags.newobject_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.newobject_tags.map((tag: any) => ({
        type: 'residentialComplex' as const,
        id: Number(tag.id),
        name: tag.text,
      })),
    );
  }

  if (responseGeoTags.regional_district_tags && responseGeoTags.regional_district_tags.length > 0) {
    tags.push(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...responseGeoTags.regional_district_tags.map((tag: any) => ({
        type: 'regional_district_id' as const,
        id: tag.id,
        name: tag.text,
      })),
    );
  }

  return tags.length > 0 ? tags : null;
}
