import { FOfferType, offerTypeFromJsonQuery } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

interface IIsNewPromoPinsAvailableParams {
  precision: number;
  currentZoom: number;
  detailsVisiblePrecision: number;
  jsonQuery: IJsonQuery;
  isNewPromoPinsEnabled: boolean;
}

export function isNewPromoPinsAvailable({
  precision,
  detailsVisiblePrecision,
  jsonQuery,
  currentZoom,
  isNewPromoPinsEnabled,
}: IIsNewPromoPinsAvailableParams) {
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  if (currentZoom < detailsVisiblePrecision) {
    return false;
  }

  if (precision >= detailsVisiblePrecision) {
    return false;
  }

  if (isNewPromoPinsEnabled) {
    return (offerType & FOfferType.FlatNew) !== 0;
  }

  return false;
}
