import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { ResizeObserver } from '@juggle/resize-observer';
import * as React from 'react';

import * as styles from './TagsCollapsible.css';
import { ITagProps } from '../Tag';
import { Tags } from '../Tags';
import { TagsList } from '../TagsList';
import { Measure } from './components/Measure';

interface ITagsCollapsibleProps {
  children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
}

export const TagsCollapsible: React.FC<ITagsCollapsibleProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [visibleTagsCount, setVisibleTagsCount] = React.useState<number | null>(null);

  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const timeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [componentWidth, setComponentWidth] = React.useState<number | null>(null);
  const tags = React.Children.toArray(children) as React.ReactElement<ITagProps>[];
  const tagsCount = React.useRef<number>(tags.length);
  tagsCount.current = tags.length;
  const shouldCollapse = React.useRef<boolean>(true);
  shouldCollapse.current = visibleTagsCount === null || tagsCount.current > visibleTagsCount;

  const computeWidth = () => {
    if (componentRef.current) {
      const { width } = componentRef.current.getBoundingClientRect();

      setComponentWidth(width);
    }
  };

  const handleMeasurementsChange = React.useCallback((oneLineTagsRaw: number, widthLeft: number) => {
    let oneLineTags = oneLineTagsRaw;

    let buttonWidth = 0;
    if (buttonRef.current) {
      buttonWidth = buttonRef.current.getBoundingClientRect().width;
    }

    if (oneLineTags < tagsCount.current) {
      /**
       * Не проверяем достаточно ли удалить один тег потому что
       * считаем что ширина тега всегда больше ширины кнопки
       */
      if (widthLeft < buttonWidth) {
        oneLineTags--;
      }
    }

    setVisibleTagsCount(oneLineTags);
  }, []);

  const resizeHandler = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = setTimeout(() => {
      computeWidth();
    }, 100);
  };

  const resizeObserver = React.useRef(new ResizeObserver(resizeHandler));

  React.useEffect(() => {
    if (resizeObserver.current && componentRef.current) {
      resizeObserver.current.observe(componentRef.current);
    }

    return () => {
      if (resizeObserver.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.current.disconnect();
      }

      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIsomorphicLayoutEffect(() => {
    computeWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = React.useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  let visibleTags = tags;
  if (isCollapsed && shouldCollapse.current && visibleTagsCount !== null) {
    visibleTags = tags.slice(0, visibleTagsCount);
  }

  return (
    <>
      {!!componentWidth && (
        <Measure width={componentWidth} onMeasurementsChange={handleMeasurementsChange}>
          <TagsList tags={tags} />
        </Measure>
      )}
      <Tags ref={componentRef}>
        {visibleTags}
        {shouldCollapse.current && (
          <button ref={buttonRef} className={styles['button']} onClick={handleButtonClick}>
            {isCollapsed ? `Ещё ${visibleTagsCount !== null ? tagsCount.current - visibleTagsCount : 100}` : 'Свернуть'}
          </button>
        )}
      </Tags>
    </>
  );
};
