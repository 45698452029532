// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  IGetNewbuildingPromoSnippetForMapRequest,
  TGetNewbuildingPromoSnippetForMapModel,
  IMappers,
  TGetNewbuildingPromoSnippetForMapResponse,
  TGetNewbuildingPromoSnippetForMapResponse_1,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetNewbuildingPromoSnippetForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'newbuilding-brand-promotion',
  pathApi: '/v1/get-newbuilding-promo-snippet-for-map/',
  interserviceAuthEnabled: true,
} as TGetNewbuildingPromoSnippetForMapModel;

export function createGetNewbuildingPromoSnippetForMapModel(
  parameters: IGetNewbuildingPromoSnippetForMapRequest,
): TGetNewbuildingPromoSnippetForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingPromoSnippetForMapOptions<TResponse200> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
  parameters: IGetNewbuildingPromoSnippetForMapRequest;
}

export async function fetchGetNewbuildingPromoSnippetForMap<TResponse200>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingPromoSnippetForMapOptions<TResponse200>): Promise<
  TGetNewbuildingPromoSnippetForMapResponse | TResponse200
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingPromoSnippetForMapModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetNewbuildingPromoSnippetForMapResponse_1);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingPromoSnippetForMapResponse;
}
