import classNames from 'clsx';
import * as React from 'react';

import { ISelectOption, TSelectValue } from '../types';
import * as styles from './selectPopup.css';
import { SelectPopupOption } from './selectPopupOption';
import { ensureChildrenVisibility } from './utils/ensureChildrenVisibility';

interface ISelectPopupProps {
  opened: boolean;
  multiple?: boolean;
  value: TSelectValue;
  options: ISelectOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOptionState(index: number, selected: boolean): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleOptionState(index: number): any;
  className?: string;
  forwardedRef: React.RefObject<HTMLDivElement>;
  postfix?: React.ReactNode;
}

interface ISelectPopupState {
  focusedIndex: number;
}

export class SelectPopup extends React.Component<ISelectPopupProps, ISelectPopupState> {
  private focusedOptionRef = React.createRef<HTMLDivElement>();

  public constructor(props: ISelectPopupProps) {
    super(props);

    this.state = {
      focusedIndex: this.getSelectedOptionIndex(),
    };
  }

  public setFocusedOptionState = (selected: boolean) => {
    const { focusedIndex } = this.state;

    if (focusedIndex >= 0) {
      this.props.setOptionState(focusedIndex, selected);
    }
  };

  public toggleFocusedOptionState = () => {
    const { focusedIndex } = this.state;

    if (focusedIndex >= 0) {
      this.props.toggleOptionState(focusedIndex);
    }
  };

  public moveFocusIndex = (delta: -1 | 1) => {
    const current = this.state.focusedIndex;
    let next = current + delta;

    if (current === -1) {
      next = 0;
    }

    if (next < 0 || next >= this.props.options.length) {
      return;
    }

    this.setState({
      focusedIndex: next,
    });
  };

  public componentDidUpdate(_: ISelectPopupProps, prevState: ISelectPopupState) {
    if (this.state.focusedIndex !== prevState.focusedIndex) {
      this.ensureSelectedOptionVisibility();
    }
  }

  public render() {
    if (!this.props.opened) {
      return null;
    }

    return (
      <div ref={this.props.forwardedRef} className={classNames(styles['select-popup'], this.props.className)}>
        {this.props.options.map((option: ISelectOption, index: number) => {
          const focused = this.state.focusedIndex === index;

          return (
            <SelectPopupOption
              key={option.value}
              forwardedRef={focused ? this.focusedOptionRef : undefined}
              option={option}
              index={index}
              multiple={this.props.multiple}
              focused={focused}
              selected={this.isSelectedOption(option)}
              onClick={this.handleOptionClick}
              onMouseMove={this.handleMouseMoveOption}
            />
          );
        })}
        {this.props.postfix}
      </div>
    );
  }

  private handleOptionClick = (_: React.MouseEvent<HTMLDivElement>, index: number) => {
    if (this.props.multiple) {
      this.props.toggleOptionState(index);
    } else {
      this.props.setOptionState(index, true);
    }
  };

  private handleMouseMoveOption = (_: React.MouseEvent<HTMLDivElement>, index: number) => {
    if (this.state.focusedIndex === index) {
      return;
    }

    this.setState({
      focusedIndex: index,
    });
  };

  private isSelectedOption = (option: ISelectOption) => {
    const { value } = this.props;

    if (Array.isArray(value)) {
      return value.includes(option.value);
    }

    return value === option.value;
  };

  private getSelectedOptionIndex = () => {
    if (this.props.multiple) {
      return -1;
    }

    return this.props.options.findIndex(option => option.value === this.props.value);
  };

  private ensureSelectedOptionVisibility() {
    const container = this.props.forwardedRef.current;
    const option = this.focusedOptionRef.current;

    if (container && option) {
      ensureChildrenVisibility(container, option);
    }
  }
}
