import { TGetNewbuildingPromoPinsForMapResponse_1 } from '../../../common/repositories/search-offers-index/v1/get-newbuilding-promo-pins-for-map';
import { IFeaturesMap } from '../../types/map';

export function prepareNewbuildingPins(data: TGetNewbuildingPromoPinsForMapResponse_1): IFeaturesMap {
  return data.promoPins.reduce<IFeaturesMap>((acc, el) => {
    const {
      coordinates,
      offerCount,
      geohash,
      minPrice = null,
      subdomain,
      newbuildingId,
      isFavorite,
      hasDiscountOffers,
    } = el;

    return {
      ...acc,
      [geohash]: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coordinates.lat, coordinates.lng],
        },
        id: geohash,
        properties: {
          featureId: geohash,
          clusterOfferIds: [],
          count: offerCount,
          favoriteIds: [],
          isViewed: false,
          isResultable: false,
          isExtended: false,
          hasNewobject: true,
          isNewbuildingPromo: true,
          minPrice,
          specialPromo: null,
          subdomain,
          newbuilding: { id: newbuildingId, isAnyFicheringPlus: true },
          isFavorite,
          hasDiscountOffers,
        },
      },
    };
  }, {});
}
