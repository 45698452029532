import { pipe } from 'ramda';

import {
  isViewed as isViewedCluster,
  isAvailable as isViewedClustersAvailable,
} from 'browser/map-search/utils/viewedClusters';

import { IGetClustersForMapResponse } from '../../../common/repositories/search-offers-map/v1/get-clusters-for-map';
import { TMapBounds } from '../../types/mapBounds';
import { queryStringWithMapBounds, isCenterZoomBounds } from '../../utils/mapBounds';

export interface IArguments {
  data: IGetClustersForMapResponse;
  mapBounds: TMapBounds;
  currentZoom: number;
}

export function prepareClusters(args: IArguments) {
  return pipe(prepareQueryStrings, prepareViewedOffers)(args);
}

function prepareQueryStrings({ data, mapBounds }: Omit<IArguments, 'experiments'>): IGetClustersForMapResponse {
  if (!isCenterZoomBounds(mapBounds)) {
    return data;
  }

  return {
    ...data,
    queryString: queryStringWithMapBounds(data.queryString, mapBounds),
    extendedQueryString: queryStringWithMapBounds(data.extendedQueryString || '', mapBounds),
  };
}

function prepareViewedOffers(data: IGetClustersForMapResponse): IGetClustersForMapResponse {
  if (!isViewedClustersAvailable()) {
    return data;
  }

  return {
    ...data,
    filtered: data.filtered?.map(cluster => ({
      ...cluster,
      isViewed: cluster.isViewed || isViewedCluster(cluster.geohash),
    })),
    extended: data.extended?.map(cluster => ({
      ...cluster,
      isViewed: cluster.isViewed || isViewedCluster(cluster.geohash),
    })),
  };
}
