import { HttpCancelError } from '@cian/peperrors/shared';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  EContactType,
  INewbuildingContactForPromoSnippetOnMap,
} from 'shared/common/repositories/newbuilding-brand-promotion/entities/GetNewbuildingPromoSnippetForMap/NewbuildingContactForPromoSnippetOnMap';
import { INewbuildingUndergroundForPromoSnippetOnMap } from 'shared/common/repositories/newbuilding-brand-promotion/entities/GetNewbuildingPromoSnippetForMap/NewbuildingUndergroundForPromoSnippetOnMap';
import { IGetNewbuildingPromoSnippetForMapRequest } from 'shared/common/repositories/newbuilding-brand-promotion/v1/get-newbuilding-promo-snippet-for-map';
import { commarize } from 'shared/common/utils/number';

import { useApplicationContext } from '../../containers/ApplicationContext';
import { selectJsonQuery } from '../../selectors/results';
import { fetchNewbuildingPromoPopup } from '../../services/newbuildingPins';
import { TFeature } from '../../types/map';

export function useNewbuildingPopup(
  initialFeature: TFeature | null = null,
): [YMaps.TNewbuildingPromoPopupManagerState, React.Dispatch<React.SetStateAction<TFeature | null>>] {
  const context = useApplicationContext();
  const jsonQuery = useSelector(selectJsonQuery);
  const previousFeatureRef = React.useRef<TFeature | null>(null);
  const [feature, setFeature] = React.useState<TFeature | null>(initialFeature);
  const [state, setState] = React.useState<YMaps.TNewbuildingPromoPopupManagerState>({ status: 'pending' });

  React.useEffect(() => {
    if (!feature) {
      return;
    }

    const previousFeature = previousFeatureRef.current;
    previousFeatureRef.current = feature;

    // не запрашиваем два раза одну и ту же фичу
    if (previousFeature && feature.id === previousFeature.id && state.status !== 'error') {
      setState({
        ...state,
      });

      return;
    }

    const newbuildingId = feature.properties.newbuilding?.id;
    const houseId = feature.properties.newbuilding?.houseId;
    if (!newbuildingId) {
      return;
    }

    const parameters: IGetNewbuildingPromoSnippetForMapRequest = {
      newbuildingId,
      houseId,
    };

    setState({ status: 'pending' });

    fetchNewbuildingPromoPopup(context, parameters)
      .then(response => {
        if (!response) {
          setState({ status: 'error' });

          return;
        }

        const minPrice = feature.properties.minPrice;

        setState({
          status: 'completed',
          newbuildingName: response.newbuildingName,
          address: response.address ?? '',
          imageUrl: response.imageUrl,
          houseName: response.houseName ?? null,
          rating: response.reviewRate ? response.reviewRate.toFixed(1) : '0',
          ratingUrl: `${response.newbuildingUrl}#reviews`,
          priceFrom: minPrice ? commarize(minPrice) : '',
          undergrounds: mapUndergrounds(response.undergrounds),
          promoName: response.newbuildingPromo?.promoName || null,
          promoUrl: (response.newbuildingPromo && `${response.newbuildingUrl}#promoList`) || null,
          newbuildingUrl: response.newbuildingUrl,
          contacts: mapContacts(response.contacts),
          newbuildingId,
          gaLabel: response.gaLabel,
          jsonQuery,
          context,
        });
      })
      .catch(error => {
        setState({ status: 'error' });
        if (error instanceof HttpCancelError) {
          return;
        }

        context.logger.error(error, {
          domain: 'useNewbuildingPopup',
          newbuildingId,
          featureId: feature.id,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  return [state, setFeature];
}

function mapUndergrounds(undergrounds: INewbuildingUndergroundForPromoSnippetOnMap[]): YMaps.IUndergroundSchema[] {
  const mappedData = [];

  for (const underground of undergrounds) {
    if (!underground.undergroundName) {
      continue;
    }

    mappedData.push({
      name: underground.undergroundName,
      lineColor: underground.lineColor ?? '',
      undergroundType: underground.undergroundType ? underground.undergroundType.toString() : '',
      timeDisplay: underground.timeDisplay,
      transportType: underground.transportType ? underground.transportType.toString() : '',
    });

    break;
  }

  return mappedData;
}

function mapContacts(contacts: INewbuildingContactForPromoSnippetOnMap[]): YMaps.INewbuildingContact[] {
  return contacts.map(item => {
    return {
      realtyUserId: item.userId,
      name: item.companyName ?? '',
      phone: item.userPhone,
      type: item.contactType === EContactType.Builder ? 'builder' : 'seller',
      siteBlockId: item.siteBlockId ?? undefined,
    };
  });
}
