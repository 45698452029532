import { IConfig } from '@cian/config/shared';

import { mapFetchClustersResponse, mapFetchClustersResponseError } from './mappers';
import { ERentTime, getTermValue, TJsonQuery } from '../../../common/packages/JsonQuery';
import {
  IGetClustersForMapOptions,
  IGetClustersForMapResponse200,
  IGetClustersForMapResponse400,
  TGetClustersForMapResponse,
  defaultConfig,
  TGetClustersForMapRequest,
  TGetClustersForMapModel,
} from '../../../common/repositories/search-offers-index/v1/get-clusters-for-map';
import { fetchGetClustersForMap as fetchGetClustersForMapOld } from '../../../common/repositories/search-offers-map/v1/get-clusters-for-map';
import { IApplicationContext } from '../../types/applicationContext';
import { withJobName } from '../../utils/withJobName';

type TOptions = Omit<
  IGetClustersForMapOptions<IGetClustersForMapResponse200, IGetClustersForMapResponse400>,
  'httpApi' | 'mappers'
>;

export async function fetchGetClustersForMap(context: IApplicationContext, options: TOptions) {
  const { config, httpApi } = context;
  const { jsonQuery } = options.parameters;
  const rentalPeriod = getTermValue('for_day')(jsonQuery as TJsonQuery);
  const isDailyRent = rentalPeriod === ERentTime.Daily;

  // TODO выпилить тоггл в задаче https://jira.cian.tech/browse/CD-183563
  if (isDailyRent || !config.get<boolean>('map-search-frontend.fetchClusters.next')) {
    return fetchGetClustersForMapOld({
      ...options,
      httpApi,
      mappers: {
        200: mapFetchClustersResponse,
        400: mapFetchClustersResponseError,
      },
    });
  }

  const { statusCode, response, headers } = await httpApi.fetch(
    createGetClustersForMapModel(context.config, options.parameters),
    options.config,
  );

  if (statusCode === 200) {
    return mapFetchClustersResponse(response);
  }

  if (statusCode === 400) {
    return mapFetchClustersResponseError(response);
  }

  return {
    statusCode,
    response,
    headers,
  } as TGetClustersForMapResponse;
}

function createGetClustersForMapModel(config: IConfig, parameters: TGetClustersForMapRequest): TGetClustersForMapModel {
  const requestConfig = {
    ...defaultConfig,
    parameters,
  };

  return withJobName(config, requestConfig);
}
