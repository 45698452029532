// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TMarkOffersAsViewedRequest,
  TMarkOffersAsViewedModel,
  IMappers,
  TMarkOffersAsViewedResponse,
  TMarkOffersAsViewedResponse_1,
  TMarkOffersAsViewedResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TMarkOffersAsViewedModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'viewed-offers',
  pathApi: '/v1/mark-offers-as-viewed/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TMarkOffersAsViewedModel;

export function createMarkOffersAsViewedModel(parameters: TMarkOffersAsViewedRequest): TMarkOffersAsViewedModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IMarkOffersAsViewedOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TMarkOffersAsViewedRequest;
}

export async function fetchMarkOffersAsViewed<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IMarkOffersAsViewedOptions<TResponse200, TResponse400>): Promise<
  TMarkOffersAsViewedResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createMarkOffersAsViewedModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TMarkOffersAsViewedResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TMarkOffersAsViewedResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TMarkOffersAsViewedResponse;
}
