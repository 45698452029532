import * as React from 'react';

import { IHotspotProperties, TFeature } from 'shared/map-search/types/map';
import { useNewbuildingPopup } from 'shared/map-search/utils/newbuildingPins';
import { trackNewbuildingPopupShow } from 'shared/map-search/utils/tracking';

import { useFeaturesContext } from '../context';
import { CLOSE_POPUP_DELAY, OPEN_POPUP_DELAY } from './constants';
import { getPopupIndent } from './utils/getPopupIndent';

interface IPopupManagerProps {
  resultsAvailable: boolean;
  detailsVisible: boolean;
}

export const NewbuildingPromoPopupManager: React.FC<IPopupManagerProps> = ({
  resultsAvailable: isResultsAvailable,
  detailsVisible: isDetailsVisible,
}) => {
  const { hotspotsLayer, balloonManager, newbuildingPopupManager } = useFeaturesContext();
  const [popupState, setCurrentFeature] = useNewbuildingPopup();
  const openTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const balloon = newbuildingPopupManager.getBalloon();

  React.useEffect(() => {
    newbuildingPopupManager.setState(popupState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState]);

  const handleFeatureHover = React.useCallback(
    (feature: TFeature) => {
      if (!feature) {
        return;
      }

      const newbuilding = feature.properties.newbuilding;

      if (!newbuilding?.isAnyFicheringPlus) {
        return;
      }

      const [x, y] = feature.geometry.coordinates;
      const indentType = getPopupIndent(feature, { isDetailsVisible, isResultsAvailable });

      setCurrentFeature(feature);

      if (newbuilding) {
        trackNewbuildingPopupShow({ newbuilding });
      }
      newbuildingPopupManager.setIndent(indentType);
      newbuildingPopupManager.open([x, y]);
    },
    [newbuildingPopupManager, setCurrentFeature, isResultsAvailable, isDetailsVisible],
  );

  const closePopup = React.useCallback(() => {
    if (openTimeoutHandler.current) {
      clearTimeout(openTimeoutHandler.current);
    }
    if (closeTimeoutHandler.current) {
      clearTimeout(closeTimeoutHandler.current);
    }

    closeTimeoutHandler.current = setTimeout(() => {
      closeTimeoutHandler.current = null;

      newbuildingPopupManager.close();
      setCurrentFeature(null);
    }, CLOSE_POPUP_DELAY);
  }, [newbuildingPopupManager, setCurrentFeature]);

  React.useEffect(() => {
    const handleHotspotClick = () => {
      newbuildingPopupManager.close();
    };

    hotspotsLayer.layer.events.add('click', handleHotspotClick);

    return () => {
      hotspotsLayer.layer.events.remove('click', handleHotspotClick);
    };
  }, [newbuildingPopupManager, hotspotsLayer, isResultsAvailable]);

  React.useEffect(() => {
    const handleHotspotMouseEnter = (event: YMaps.IEvent) => {
      if (openTimeoutHandler.current) {
        clearTimeout(openTimeoutHandler.current);
      }
      if (closeTimeoutHandler.current) {
        clearTimeout(closeTimeoutHandler.current);
      }

      openTimeoutHandler.current = setTimeout(() => {
        openTimeoutHandler.current = null;

        const activeObject = event.get<YMaps.IHotspotLayerObject<IHotspotProperties>>('activeObject');
        const activeObjectProperties = activeObject.getProperties();

        handleFeatureHover(activeObjectProperties.originalFeature);
      }, OPEN_POPUP_DELAY);
    };

    hotspotsLayer.layer.events.add('mouseenter', handleHotspotMouseEnter);

    return () => {
      hotspotsLayer.layer.events.remove('mouseenter', handleHotspotMouseEnter);
    };
  }, [hotspotsLayer, handleFeatureHover]);

  React.useEffect(() => {
    const handleBalloonMouseEnter = (event: YMaps.IEvent) => {
      if (openTimeoutHandler.current) {
        clearTimeout(openTimeoutHandler.current);
      }
      if (closeTimeoutHandler.current) {
        clearTimeout(closeTimeoutHandler.current);
      }

      openTimeoutHandler.current = setTimeout(() => {
        openTimeoutHandler.current = null;

        const objectId = event.get<string>('objectId');
        const feature = balloonManager.objectManager.objects.getById<TFeature>(objectId);

        handleFeatureHover(feature);
      }, OPEN_POPUP_DELAY);
    };

    balloonManager.objectManager.objects.events.add('mouseenter', handleBalloonMouseEnter);
    balloon.events.add('mouseenter', handleBalloonMouseEnter);

    return () => {
      balloonManager.objectManager.objects.events.remove('mouseenter', handleBalloonMouseEnter);
      balloon.events.remove('mouseenter', handleBalloonMouseEnter);
    };
  });

  React.useEffect(() => {
    hotspotsLayer.layer.events.add('mouseleave', closePopup);

    return () => {
      hotspotsLayer.layer.events.remove('mouseleave', closePopup);
    };
  }, [hotspotsLayer, closePopup]);

  React.useEffect(() => {
    balloonManager.objectManager.objects.events.add('mouseleave', closePopup);
    balloon.events.add('mouseleave', closePopup);

    return () => {
      balloonManager.objectManager.objects.events.remove('mouseleave', closePopup);
      balloon.events.remove('mouseleave', closePopup);
    };
  }, [balloonManager, closePopup]);

  return null;
};
