import { POINT_RADIUS, DOT_SIZE_SMALL, DOT_SIZE_BIG } from '../../../constants/map';
import { TFeature } from '../../../types/map';

export enum FPointType {
  Regular = 1,
  ResidentialComplex = 2,
  Extended = 4,
  Viewed = 8,
}

export interface IPointConfig {
  radius: number;
  color: string;
  glow: boolean;
  glowRadius: number;
  textColor?: string;
  borderColor?: string;
  borderWidth?: number;
  count?: string;
  hasContourShadow: boolean;
}

export interface IGetPointConfigParameters {
  feature: TFeature;
  isResidentialComplexesListing: boolean;
  isResultable: boolean;
  isDetailed: boolean;
  // @todo выпилить эксп CD-159979
  showOnlyPrice: boolean;
}

const COLORS_CONFIG_MAP: { [key: number]: Pick<IPointConfig, 'color' | 'borderColor' | 'textColor'> } = {
  [FPointType.Regular]: { color: '#0468ff', borderColor: '#fff' },
  [FPointType.Regular | FPointType.Viewed]: { color: '#83b4ff', borderColor: '#fff' },
  [FPointType.ResidentialComplex]: { color: '#3ac500', borderColor: '#fff' },
  [FPointType.ResidentialComplex | FPointType.Viewed]: { color: '#97da7c', borderColor: '#fff' },
  [FPointType.Extended]: { color: '#fff', borderColor: '#0468ff', textColor: '#152242' },
  [FPointType.Extended | FPointType.Viewed]: { color: '#fff', borderColor: '#83b4ff', textColor: '#152242' },
};

// @todo выпилить эксп CD-159979
const POSUTOCHNO_EXP_POINT_CONFIG = {
  radius: 1,
  color: 'rgba(0,0,0,0)',
  glow: false,
  glowRadius: 0,
  textColor: 'rgba(0,0,0,0)',
  borderColor: 'rgba(0,0,0,0)',
  borderWidth: 1,
  count: '0',
  hasContourShadow: false,
};

export function getPointConfig({
  feature,
  isResidentialComplexesListing,
  isResultable,
  isDetailed,
  // @todo выпилить эксп CD-159979
  showOnlyPrice,
}: IGetPointConfigParameters): IPointConfig {
  // @todo выпилить эксп CD-159979
  if (showOnlyPrice) {
    return POSUTOCHNO_EXP_POINT_CONFIG;
  }

  const pointType: number =
    feature.properties.isExtended && !feature.properties.isActive
      ? FPointType.Extended
      : ((isResidentialComplexesListing || isDetailed || feature.properties.newbuilding?.isAnyFicheringPlus) &&
            feature.properties.hasNewobject) ||
          feature.properties.isAnyFromKp
        ? FPointType.ResidentialComplex
        : FPointType.Regular;

  const colors =
    COLORS_CONFIG_MAP[
      pointType | (feature.properties.isViewed && !feature.properties.isActive ? FPointType.Viewed : 0)
    ];

  const glow =
    (feature.properties.isGlow && (!feature.properties.favoriteIds || feature.properties.favoriteIds.length === 0)) ||
    false;

  let glowRadius = 0;
  if (feature.properties.isGlow) {
    glowRadius = POINT_RADIUS + 4;

    if (feature.properties.isActive) {
      glowRadius = 8;
    }
  }

  let borderWidth = 1;
  if (isResultable && !isDetailed) {
    borderWidth = 2;
  } else if (isDetailed) {
    if (feature.properties.isExtended && !feature.properties.isActive) {
      borderWidth = 2;
    } else if (feature.properties.isGlow && !feature.properties.isActive) {
      borderWidth = 1;
    } else {
      borderWidth = 0;
    }
  }

  let radius = POINT_RADIUS;
  if (feature.properties.isActive) {
    radius = 3;
  } else {
    if (isResultable && !isDetailed) {
      if (feature.properties.count === 1) {
        radius = DOT_SIZE_SMALL;
      } else if (feature.properties.count > 1) {
        radius = DOT_SIZE_BIG;
      }
    }

    if (!isResultable && feature.properties.isExtended) {
      radius -= 1;
    }
  }

  let count: string | undefined;
  if (isResultable && !isDetailed) {
    if (feature.properties.count > 1 && feature.properties.count <= 9) {
      count = String(feature.properties.count);
    } else if (feature.properties.count > 9) {
      count = '9+';
    }
  }

  return { ...colors, glow, borderWidth, radius, glowRadius, count, hasContourShadow: isResultable && !isDetailed };
}
