// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TIsNewbuildingFavoritesRequest,
  TIsNewbuildingFavoritesModel,
  IMappers,
  TIsNewbuildingFavoritesResponse,
  TIsNewbuildingFavoritesResponse_1,
  TIsNewbuildingFavoritesResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TIsNewbuildingFavoritesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'favorites',
  pathApi: '/v1/is-newbuilding-favorites/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TIsNewbuildingFavoritesModel;

export function createIsNewbuildingFavoritesModel(
  parameters: TIsNewbuildingFavoritesRequest,
): TIsNewbuildingFavoritesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IIsNewbuildingFavoritesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TIsNewbuildingFavoritesRequest;
}

export async function fetchIsNewbuildingFavorites<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IIsNewbuildingFavoritesOptions<TResponse200, TResponse400>): Promise<
  TIsNewbuildingFavoritesResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createIsNewbuildingFavoritesModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TIsNewbuildingFavoritesResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TIsNewbuildingFavoritesResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TIsNewbuildingFavoritesResponse;
}
