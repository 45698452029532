import { IMultiSuggestResult, IResultSuggestions, IAddressSuggest } from '@cian/geosuggest-widget';
import { UnknownError } from '@cian/peperrors/shared';
import { pathOr } from 'ramda';

import { ResponseError } from 'shared/common/errors';
import {
  EQueryParameters_1 as EOfferType,
  EQueryParameters_2 as ESource,
  ISuggestResponse200,
  ISuggestResponse400,
  fetchSuggest,
} from 'shared/common/repositories/geo-suggest/v2/suggest';
/**
 * FIXME убрать зависимость фильтров от карты
 */
// eslint-disable-next-line import/no-restricted-paths
import { TThunkAction } from 'shared/map-search/types/redux';
// eslint-disable-next-line import/no-restricted-paths
import { loadYMapsApi } from 'shared/map-search/utils/loadYMapsApi';
// eslint-disable-next-line import/no-restricted-paths
import { actionGenerator } from 'shared/map-search/utils/redux/actionGenerator';

import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from './helpers';
import { ESuggestionsActionType } from './types';

const setStatusLoading = actionGenerator<ESuggestionsActionType.Loading>(ESuggestionsActionType.Loading);
const setStatusSucceed = actionGenerator<ESuggestionsActionType.Succeed>(ESuggestionsActionType.Succeed);
const setStatusFailed = actionGenerator<ESuggestionsActionType.Failed>(ESuggestionsActionType.Failed);

const GEO_SUGGESTION_ALLOWED_KEYS: string[] = ['cities', 'newbuildings', 'regionalDistricts', 'villages'];
const GEO_SUGGESTION_SORT_ORDER: string[] = ['regionalDistricts', 'cities', 'addresses', 'newbuildings', 'villages'];

/**
 * Плохое, но быстрое решение.
 * TODO: Сделать для фильтров свой store/другое решение с запросами
 */
export function suggest(query: string): TThunkAction<Promise<IMultiSuggestResult | null>> {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      filters: { jsonQuery },
      mapBounds: { bounds: boundedBy },
    } = getState();

    dispatch(setStatusLoading());

    try {
      const queryWithoutMotherland = query.trim().replace(/([, ]*Россия$|^Россия[, ]*)/gi, '');

      const yandexSuggestionsRequest = loadYMapsApi(['suggest'])
        .then(ymaps => ymaps.suggest(`Россия, ${queryWithoutMotherland}`, { boundedBy }))
        .catch(error => {
          logger.error('Failed to get yandex suggestions', error);

          return null;
        });

      // Временно убираем
      const suggestionsRequest = fetchSuggest<ISuggestResponse200, ISuggestResponse400>({
        httpApi,
        parameters: {
          query,
          regionId: pathOr<number[]>([-1], ['region', 'value'], jsonQuery)[0],
          dealType: dealTypeFromJsonQuery(jsonQuery),
          offerType: offerTypeFromJsonQuery(jsonQuery) as EOfferType,
          source: ESource.Map,
        },
      })
        .then(response => {
          if (response.statusCode !== 200) {
            throw new ResponseError({
              domain: 'suggestions#suggest',
              message: response.response.message || '',
              details: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error: response.response.errors as any,
              },
            });
          }

          return response.response;
        })
        .catch(error => {
          logger.error(error);

          return null;
        });

      const [suggestionsData, yandexSuggestionsData] = await Promise.all([
        suggestionsRequest,
        yandexSuggestionsRequest,
      ]);

      if (!suggestionsData && !yandexSuggestionsData) {
        throw new UnknownError({
          domain: 'suggestions#suggest',
          message: 'Failed to get any suggestions',
        });
      }

      let suggestions: IResultSuggestions = {};
      let sortOrder: string[] = [];
      if (yandexSuggestionsData && yandexSuggestionsData.length > 0) {
        suggestions = { ...suggestions, addresses: yandexSuggestionsData.map(removeMotherland) as IAddressSuggest[] };
        sortOrder = ['addresses'];
      }

      if (suggestionsData && suggestionsData.status === 'ok') {
        const { suggestions: geoSuggestions } = suggestionsData.data;

        Object.keys(geoSuggestions).forEach((key: keyof IResultSuggestions) => {
          if (GEO_SUGGESTION_ALLOWED_KEYS.includes(key as string)) {
            // добавляем все разрешенные данные)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const nextSuggestions = (geoSuggestions as any)[key];
            if (nextSuggestions && nextSuggestions.items.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              suggestions[key] = nextSuggestions.items as any;
            }
          }
        });

        sortOrder = GEO_SUGGESTION_SORT_ORDER; // сортировку фиксируем на клиенте(https://jira.cian.tech/browse/CD-84790)
      }

      dispatch(setStatusSucceed());

      return { suggestions, sortOrder };
    } catch (error) {
      logger.error(error);

      dispatch(setStatusFailed());

      return null;
    }
  };
}

function removeMotherland<T>(item: YMaps.ISuggestResult<T>): YMaps.ISuggestResult<T> {
  return {
    ...item,
    displayName: item.displayName.replace(/, Россия$/, ''),
  };
}
