import {
  TGetClustersForMapResponse_1,
  IGetClustersForMapResponse200,
  IGetClustersForMapResponse400,
  TGetClustersForMapResponseError,
} from 'shared/common/repositories/search-offers-index/v1/get-clusters-for-map';

export const mapFetchClustersResponse = (response: TGetClustersForMapResponse_1): IGetClustersForMapResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};

export const mapFetchClustersResponseError = (
  response: TGetClustersForMapResponseError,
): IGetClustersForMapResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
