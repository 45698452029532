import { CancellationToken } from '@cian/http-api/shared';
import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { assertUnknownError } from './assert';
import { fetchGetClustersForMap } from './fetchGetClustersForMap';
import { IResolveOfferParameters } from './types';
import {
  TGetClustersForMapResponse_1,
  TGetClustersForMapResponse,
} from '../../../common/repositories/search-offers-index/v1/get-clusters-for-map';
import { IApplicationContext } from '../../types/applicationContext';

const RETRY_TRIES = 3;
const RETRY_MAX_TIMEOUT = 100;
const CANCEL_PREVIOUS_REQUEST = false;

export async function fetchClusters(
  context: IApplicationContext,
  parameters: IResolveOfferParameters,
): Promise<TGetClustersForMapResponse_1> {
  const { params, subdomain } = parameters;
  const { cancellationTokenManager } = context;

  function getOr<T>(key: string, defaultValue: T): T {
    const value: T | null | undefined = context.config.get(key);

    if (value === null || value === undefined) {
      return defaultValue;
    }

    return value;
  }

  const retryTries: number = getOr('mapSearchFrontend.fetchClusters.retryTries', RETRY_TRIES);
  const retryMaxTimeout: number = getOr('mapSearchFrontend.fetchClusters.retryMaxTimeout', RETRY_MAX_TIMEOUT);
  const cancelPreviousRequest: boolean = getOr(
    'mapSearchFrontend.fetchClusters.cancelPreviousRequest',
    CANCEL_PREVIOUS_REQUEST,
  );

  let cancellationToken: CancellationToken | undefined;
  if (cancelPreviousRequest && cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious('fetchClusters');
  }

  async function tryFetch(n: number): Promise<TGetClustersForMapResponse> {
    try {
      return (await fetchGetClustersForMap(context, {
        parameters: { ...params, subdomain },
        config: {
          cancellationToken,
        },
      })) as TGetClustersForMapResponse;
    } catch (error) {
      if (error instanceof HttpBadStatusCodeError && n < retryTries) {
        // eslint-disable-next-line no-console
        console.log(`Перезапрос кластеров после ошибки №${n}`);

        await new Promise(resolve => {
          setTimeout(resolve, Math.random() * retryMaxTimeout);
        });

        return tryFetch(n + 1);
      }

      throw error;
    }
  }

  const response = await tryFetch(0);

  assertUnknownError(response);

  return response.response;
}
